import { UserStatus } from '@ml/shared/enums';
import { Action, ares, Attributes, CoreUser, getAllowedFields, isCompanyAdmin, isManager, isProvider, isSuperadmin, Resource } from '@ml/shared/permissions';
import { cloneDeep, find, flatten } from 'lodash-es';
import type { Ref } from 'vue';

import { License, MeQuery, Provider, Role, Session, User } from '@/graphql';

export type ResolvedUser = MeQuery['me']['user'];

interface AuthStateType {
  user: ResolvedUser | null;
  session: MeQuery['me']['session'],
  avatars: { path: string; }[],
  trial: string | null | undefined,
}

interface ARES { a: Action, res: Resource }

type MaybeRef<T> = Partial<T> | Ref<Partial<T>>;

export type AccessCtx = MaybeRef<Provider> | {
  provider: MaybeRef<Provider>;
  license?: MaybeRef<License>;
};

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthStateType => ({
    user: null,
    session: null,
    avatars: [],
    trial: '',
  }),

  actions: {
    setAvatar(path: string) {
      if (this.avatars.length === 0) {
        this.avatars.push({
          path,
        });
      }

      this.avatars[0].path = path;
    },
    setTimeZone(timezone: string) {
      const user = {
        ...this.user,
      };
      user.timezone = timezone;
      // @ts-ignore: ??
      this.user = user;
    },
  },

  getters: {
    isAuthorized: state => !!state.user,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    organization: state => find(state.user?.organizations, {
      id: state.user?.org_id,
    }),
    isRootOrg(): () => boolean {
      // @ts-ignore: ??
      // eslint-disable-next-line
      return !!this?.organization?.is_root;
    },
    can: state => (action: string) => {
      if (!state.user) {
        return false;
      }

      const permissions = flatten(state.user?.roles?.map(r => r.permissions as string[]));

      return permissions.includes('superuser') || permissions.includes(action);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scopes(state): (ares: string) => (string | string[])[] {
      const merged = this.mergedPermissions;

      return (ares: string) => {
        if (!merged[ares]) {
          return [];
        }

        return merged[ares];
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasAnyScope(state): (a: string, res?: string) => boolean {
      const admin = this.isAdmin;
      const { scopes } = this;

      return (a: string, res?: string) => {
        const n = res ? ares(a as Action, res as Resource) : a;

        if (admin) {
          return true;
        }

        return scopes(n)?.length > 0;
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasScopes(state): (a: Action, res: Resource, ...required: string[]) => boolean {
      const admin = this.isAdmin;
      const { scopes } = this;

      return (a: Action, res: Resource, ...required: string[]) => {
        const k = ares(a, res);

        if (admin) {
          return true;
        }

        return required.every(s => scopes(k).includes(s));
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasPageAccess(state): (page: Attributes.Page) => boolean {
      const admin = this.isAdmin;
      const root = this.isRootOrg;
      const provider = this.isProvider;
      const merged = this.mergedPermissions;

      const P = Attributes.Page;

      const providerPages = new Set([
        P.Dashboard,
        P.AllLicenses,
        P.AllDocuments,
        P.AllCredentials,
        P.CmeRequirements,
        P.CmeCredits,
      ]);

      const rootPages = new Set([
        P.Organizations,
      ]);

      const k = ares(Action.Meta, Resource.Page);

      return (page: Attributes.Page): boolean => {
        if (!root && rootPages.has(page)) {
          return false;
        }

        if (admin) {
          return true;
        }

        if (provider && providerPages.has(page)) {
          return true;
        }

        return k in merged && merged[k].includes(page as unknown as string);
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasAssignAccess(state): boolean {
      if (this.isAdmin) {
        return true;
      }

      const merged = this.mergedPermissions;

      return 'assign.provider' in merged && merged['assign.provider'].length > 0;
    },

    hasFieldAccess(): ({ a, res }: ARES, field: string) => boolean {
      const merged = this.mergedPermissions;
      const alwaysAllow = this.isAdmin || this.isProvider;

      return ({ a, res }: ARES, field: string): boolean => {
        const attributes = merged[ares(a, res)];

        if (alwaysAllow) {
          return true;
        }

        const fields = getAllowedFields(attributes);

        // TODO check assigned

        return fields.includes(field);
      };
    },

    hasProviderAccess(): ({ a, res }: ARES, opts?: AccessCtx) => boolean {
      const { evaluate } = useTagEval();

      const { user } = this;
      const superadmin = this.isSuperadmin;
      const merged = this.mergedPermissions;
      const companyAdmin = this.isCompanyAdmin;

      return ({ a, res }: ARES, opts?: AccessCtx): boolean => {
        const o = unref(opts);

        let provider;
        let license;

        if (o && 'provider' in o) {
          provider = unref(o.provider);
          license = unref(o.license);
        } else if (o && 'id' in o) {
          provider = o;
        }

        if (superadmin) {
          return true;
        }

        if (user?.provider?.id === provider?.id) {
          return true;
        }

        if (companyAdmin && provider?.organizations?.some(o => o.id === user?.org_id)) {
          return true;
        }

        const allowedTags = cloneDeep(merged[ares(a, res)]);

        if (!allowedTags || !provider || !user) {
          return false;
        }

        // fix bind actions with custom field
        for (const item of allowedTags) {
          if (!Array.isArray(item)) continue;

          for (const [index, tag] of item.entries()) {
            if (tag.includes(Attributes.Common.Field)) {
              item.splice(index, 1);
            }
          }
        }

        return evaluate(allowedTags, {
          user: user as User,
          license: license as License,
          provider: provider as Provider,
        });
      };
    },
    canBeAssigned: state => state.user?.roles?.some(r => r.provider_assign),
    mergedPermissions: state => {
      // @ts-ignore: ??
      const roles: Role[] = state.user?.roles ?? [];

      const merged: Record<string, (string | string[])[]> = {};

      for (const role of roles) {
        for (const [ares, tags] of Object.entries(role.permissions)) {
          if (!merged[ares as string]) {
            merged[ares as string] = [];
          }

          merged[ares as string].push(...tags as string[]);
        }
      }

      return merged;
    },
    avatar: state => {
      if (state.avatars[0]) {
        return state.avatars[0];
      }

      if (state.user?.avatars && state.user.avatars.length > 0 && state.user.avatars[0]) {
        return state.user.avatars[0];
      }

      return '';
    },
    isProvider() {
      // @ts-ignore: ??
      return isProvider(this.user);
    },
    isUser() {
      // @ts-ignore: ??
      return isProvider(this.user);
    },
    isSuperUser() {
      // @ts-ignore: ??
      return !isProvider(this.user);
    },
    isSuperadmin: state => Boolean(state.user?.roles && isSuperadmin(state.user as CoreUser)),
    isCompanyAdmin: state => state.user?.roles && isCompanyAdmin(state.user as CoreUser),
    isManager: state => state.user?.roles && isManager(state.user as CoreUser),
    isAdmin(): boolean {
      return Boolean(this.isSuperadmin || this.isCompanyAdmin);
    },
    lastSession(): Session | object {
      if (!this.user?.sessions || (this.user?.sessions?.nodes && this.user.sessions.nodes.length === 0)) {
        return {};
      }

      return this.user?.sessions.nodes[0];
    },
    isHold(): boolean {
      if (!isProvider(this.user as CoreUser) || !this.user?.status) {
        return false;
      }

      return UserStatus[this.user?.status] === UserStatus.Hold;
    },
    isTrial(): boolean {
      if (!isProvider(this.user as CoreUser) || !this.user?.status) {
        return false;
      }

      return UserStatus[this.user?.status] === UserStatus.Trial;
    },
    isExpired(): boolean {
      if (!isProvider(this.user as CoreUser) || !this.user?.status) {
        return false;
      }

      return UserStatus[this.user?.status] === UserStatus.Expired;
    },
  },
});

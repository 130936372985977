export const NPI_FORM = Symbol('forms/npi');
export const GROUP_FORM = Symbol('forms/groupForm');
export const LOGIN_FORM = Symbol('forms/LoginForm');
export const LICENSE_FORM = Symbol('forms/license');
export const REQUEST_LICENSE_FORM = Symbol('forms/request-license');
export const INVITE_PROVIDER_FORM = Symbol('forms/invite-provider');
export const DOCUMENTS_FORM = Symbol('forms/documents');
export const EDUCATION_FORM = Symbol('forms/education');
export const EXAM_FORM = Symbol('forms/exam');
export const BASIC_INFO_FORM = Symbol('forms/basicInfo');
export const BIRTH_INFO_FORM = Symbol('forms/birthInfo');
export const MORE_NAMES_FORM = Symbol('forms/moreNames');
export const WORK_HISTORY_FORM = Symbol('forms/workHistory');
export const PROVIDER_TO_GROUP = Symbol('forms/userRoleForm');
export const DEMOGRAPHIC_INFO_FORM = Symbol('forms/demographicInfo');
export const PRACTICE_EMPLOYER_FORM = Symbol('forms/practiceEmployer');
export const BOARD_CERTIFICATION_FORM = Symbol('forms/boardCertification');
export const FACILITY_AFFILIATION_FORM = Symbol('forms/facilityAffiliation');
export const TEAM_MEMBER_FORM = Symbol('forms/team-member');
export const IMPOT_LOG_FORM = Symbol('forms/importLog');
export const CHANGELOG_FORM = Symbol('forms/changelog');
export const ACCESSLOG_FORM = Symbol('forms/accesslog');
export const PEER_REFERENCES_FORM = Symbol('forms/peer-references');
export const MALPRACTICE_INSURANCE_FORM = Symbol('forms/malpractice_insurance');
export const NCSBN_ID_FORM = Symbol('forms/ncsbnId');
export const NPDB_FORM = Symbol('forms/npdb');
export const HEALTHCARE_PAYORS_FORM = Symbol('forms/healthcare-payors');
export const CME_FORM = Symbol('forms/cme');
export const CME_LICENSE_REQUIREMENTS_FORM = Symbol('forms/cme-license-requirements');
export const CME_CREDITS_HISTORY_FORM = Symbol('forms/cme-credits-history');
export const SHARE_PROFILE_FORM = Symbol('forms/share-profile');
export const ACTIVE_SHARING_FORM = Symbol('forms/active-sharing');

export enum ProtectFields {
  ALL = '*',
}

export enum Action {
  // meta
  Meta = '_meta',

  // providers
  Assign = 'assign',

  // CRUD
  Create = 'create',
  Read = 'read',
  Edit = 'edit',
  Delete = 'delete',
}

export enum Resource {
  // meta
  Page = 'page',

  // static
  State = 'state',
  ProviderType = 'provider-type',
  LegalQuestion = 'legal-question',
  SelectOption = 'select-option',

  // provider scoped
  Npi = 'npi',
  NPDB = 'npdb',
  Address = 'address',
  Assignee = 'assignee',
  Document = 'document',
  Additional = 'additional',
  Education = 'education',
  Changelog = 'changelog',
  Exam = 'exam',
  PracticeEmployer = 'practice-employer',
  FacilityAffiliation = 'facility-affiliation',
  WorkHistory = 'work-history',
  PeerReference = 'peer-reference',
  License = 'license',
  LicenseRequest = 'license-request',
  BoardCertification = 'board-certification',
  Credential = 'credential',
  LegalAnswer = 'legal-answer',
  MalpracticeInsurance = 'malpractice-insurance',
  HealthcarePayor = 'healthcare-payor',
  BirthInfo = 'birth_info',
  DemographicInfo = 'demographic_info',
  MoreNames = 'more_names',
  Cme = 'cme',
  ProviderShare = 'provider-share',
  Accesslog = 'accesslog',

  // special resources
  Provider = 'provider',
  Organization = 'organization',

  // org resources
  User = 'user',
  Role = 'role',
  ProviderGroup = 'provider-group',
}

export const StaticResources = [
  Resource.State,
  Resource.SelectOption,
  Resource.ProviderType,
  Resource.LegalQuestion,
];

export const ProviderResources = [
  Resource.Npi,
  Resource.Address,
  Resource.Additional,
  Resource.Changelog,
  Resource.Education,
  Resource.Exam,
  Resource.Document,
  Resource.PracticeEmployer,
  Resource.FacilityAffiliation,
  Resource.WorkHistory,
  Resource.PeerReference,
  Resource.BoardCertification,
  Resource.Credential,
  Resource.LegalAnswer,
  Resource.LegalQuestion,
  Resource.License,
  Resource.LicenseRequest,
  Resource.Credential,
  Resource.MalpracticeInsurance,
  Resource.HealthcarePayor,
  Resource.BirthInfo,
  Resource.DemographicInfo,
  Resource.MoreNames,
  Resource.Cme,
];

export const OrgResources = [
  Resource.User,
  Resource.Role,
  // Resource.Document,
  // Resource.ProviderGroup,
];

import { array, boolean, literal, number, object, string } from 'zod';

import { jsonAddress } from './helpers';

export const OrganizationDTO = object({
  id: number().optional(),
  name: string().min(1),
  address: jsonAddress.default({}),
  npi: string().nullish(),
  tax_id: string().nullish(),
  email: string().email(),
  phone: string().nullish(),
  fax: string().nullish(),
  admins: array(
    string().email().optional().or(literal('')),
  ).nullish().default(['']),
});

export const InviteMemberDTO = object({
  first_name: string().min(1),
  last_name: string().min(1),
  email: string().email().min(1),
  password: string().min(6),
  details: boolean().default(false),
  role: string().min(1),
  organization: number(),
});

export enum ConfirmDialogColors {
  Green = 'green',
  Primary = 'primary',
  Danger = 'danger',
  Warning = 'warning',
}

export interface ConfirmDialogOptions {
  target?: HTMLElement | undefined;
  header?: string | undefined;
  message?: string | undefined;
  group?: string | undefined;
  style?: Record<string, unknown>;
  position?: string;
  icon?: string | undefined;
  blockScroll?: boolean | undefined;
  accept?: () => void;
  reject?: () => void;
  onHide?: () => void;
  acceptLabel?: string | undefined;
  rejectLabel?: string | undefined;
  acceptIcon?: string | undefined;
  rejectIcon?: string | undefined;
  acceptClass?: string | undefined;
  rejectClass?: string | undefined;
  defaultFocus?: string | undefined;
  color: ConfirmDialogColors
}

interface GlobalsStoreType {
  providerTopBar: boolean;
  wsConnectionStatus: boolean;
  routerLoading: boolean;
  throttle: undefined | ReturnType<typeof setTimeout>;
  isNotActivity: boolean;
}

export const useGlobalsStore = defineStore({
  id: 'globals',

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  state: () => ({
    providerTopBar: false,
    wsConnectionStatus: true,
    routerLoading: false,
    throttle: undefined,
    isNotActivity: false,
  } as GlobalsStoreType),

  actions: {
    setConnectionStatus(value: boolean) {
      if (value) {
        this.wsConnectionStatus = true;
        clearTimeout(this.throttle);

        return;
      }

      if (this.throttle) {
        return;
      }

      this.throttle = setTimeout(() => {
        this.throttle = undefined;
        this.wsConnectionStatus = false;
      }, 10e3);
    },
  },
  getters: {
    connectionStatus: state => state.wsConnectionStatus,
  },
});

import { sensDocTypes } from '@ml/shared';
import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { Ref, toRef } from 'vue';

import { SidebarComponent } from '@/modules/sidebar';
import { useConfirmVerificationRequired } from '@/use/confirm-verification-required';

export interface ICommonSidebarContext {
  readonly?: boolean;
  hidden?: boolean;
}

export type ContextKey = keyof ISidebarContext;

export type MergedContext<T extends ContextKey> = ICommonSidebarContext & Partial<ISidebarContext[T]>;

export const useSidebarStore = defineStore('sidebar', () => {
  const context = ref<{ [T in ContextKey]?: MergedContext<T> }>({});
  const active = ref<ContextKey | undefined>(undefined);
  const forceClose = ref(false);
  const component = ref({});
  const sensForms = new Set([
    BASIC_INFO_FORM,
    DOCUMENTS_FORM,
    LOGIN_FORM,
  ]);

  const globals = useGlobalsStore();

  const isNotActivity = toRef(globals, 'isNotActivity');

  const { confirmWithoutDecrypt } = useConfirmVerificationRequired();

  const processShow = <T extends ContextKey>(form: T, ctx?: MergedContext<T>) => {
    // @ts-ignore: ??
    if (ctx) context.value[form] = cloneDeep(ctx);

    if (active.value && active.value !== form) {
      forceClose.value = true;
      // eslint-disable-next-line no-return-assign
      setTimeout(() => forceClose.value = false, 100);
    }

    active.value = form;
  };

  const show = <T extends ContextKey>(form: T, ctx?: MergedContext<T>) => {
    if (useAuthStore().isHold && !ctx?.readonly) return;

    if (isNotActivity.value && ((form !== DOCUMENTS_FORM && sensForms.has(form) && ctx?.data)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      || (form === DOCUMENTS_FORM && sensDocTypes.has(ctx?.data?.type)))) {
      confirmWithoutDecrypt(() => {
        isNotActivity.value = false;
        processShow(form, ctx);
      });
    }

    if (!isNotActivity.value || (isNotActivity.value && (!sensForms.has(form)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      || (form === DOCUMENTS_FORM && !sensDocTypes.has(ctx?.data?.type)) || !ctx?.data))) processShow(form, ctx);
  };

  const setReadonly = (readonly: boolean) => {
    if (!active.value) return;

    const ctx = context.value[active.value] as { [T in ContextKey]?: MergedContext<T> };

    if (ctx !== undefined) {
      // @ts-ignore: that's ok
      context.value[active.value] = {
        ...ctx,
        readonly,
      };
    }
  };

  const addContext = <T extends ContextKey>(form: T, ctx: Partial<MergedContext<T>>) => {
    if (!active.value) return;

    const current = context.value?.[form];

    // @ts-ignore: ??
    context.value[form] = {
      ...current,
      ...ctx,
    };
  };

  const hide = (preserveContext = false) => {
    if (!preserveContext && active.value) context.value[active.value] = {};

    active.value = undefined;
  };

  const setComponent = <T extends ContextKey>(form: T, value: Ref<SidebarComponent['value']>) => {
    // @ts-ignore: ??
    component.value[form] = {};
    // @ts-ignore: ??
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    component.value[form].value = value;
  };

  const setComponentFunc = <T extends ContextKey>(
    form: T,
    name: 'onSidebarClose' | 'onScrollDown',
    value: SidebarComponent['onSidebarClose'] | SidebarComponent['onScrollDown']) => {
    // @ts-ignore: ??
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    component.value[form][name] = value;
  };

  const getContext = <T extends ContextKey>(form: T): MergedContext<T> => {
    const ctx = context.value;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return ctx[form];
  };

  return {
    show,
    setReadonly,
    addContext,
    hide,
    setComponent,
    setComponentFunc,
    getContext,
    context,
    active,
    forceClose,
    component,
  };
});

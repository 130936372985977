// @ts-ignore: ??
import ConfirmationEventBus from 'primevue/confirmationeventbus';

import { ConfirmDialogColors, ConfirmDialogOptions } from '../models';

const open = (options: ConfirmDialogOptions) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  ConfirmationEventBus.emit('confirm', {
    ...options,
    group: options.group ?? 'default',
    // @ts-ignore: ??
    color: options.color ?? ConfirmDialogColors.Primary,
  });
};

export const useConfirmDialog = () => open;

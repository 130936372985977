import { FormContext, Path, PathValue } from 'vee-validate';

import { AccesslogType, DecryptQueryVariables, useDecryptQuery } from '@/graphql';

const decrypt = (data: DecryptQueryVariables): Promise<string> => new Promise((resolve, reject) => {
  const { onResult, onError } = useDecryptQuery(data);

  onResult(result => {
    if (!result.loading) resolve(result?.data?.decrypt?.value);
  });
  onError(reject);
});

interface AutoDecryptOptions<T extends Record<string, unknown>> {
  form: FormContext<T>;
  data: { action: string; type: AccesslogType };
  values: string[];
  provider_id: number;
}

export const useDecrypt = () => {
  const isOpen = ref(false);

  const autoDecrypt = async <T extends Record<string, unknown>>(options: AutoDecryptOptions<T>) => {
    const {
      data, form, values, provider_id,
    } = options;

    for (const key of values) {
      if (form.values[key]) {
        const value = await decrypt({
          data: {
            hash: form.values[key] as string,
            direction: key,
            ...data,
            isOpen: !isOpen.value,
            provider_id,
          },
        });
        if (value) form.setFieldValue(key as Path<T>, value as PathValue<T, Path<T>>);
        isOpen.value = true;
      }
    }
  };

  return {
    decrypt,
    autoDecrypt,
  };
};
